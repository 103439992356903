import React from 'react'
import Layout from '../layouts/en'
import SubTitle from '../components/Titles/SubTitle'

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SubTitle>Page not found.</SubTitle>
  </Layout>
)

export default NotFoundPage
